import React from 'react'
import { graphql } from 'gatsby'

import CategoryPageLayout from '../components/category-page/layout'

export const query = graphql`
  query ($sectionId: String, $categoryId: String) {
    category(id: {eq: $categoryId}) {
      id
      title
      name

      overrideFilters
      filterRules {
        ...CategoryPageFilterRuleFragment
      }
      
      products {
        ...CategoryPageProductFragment
        ...ProductCardFragment
      }
    }
    section(id: {eq: $sectionId}) {
      ...CategoryPageSectionFragment
    }
  }
`

const CategoryPageTemplate = ({
  data: {
    category,
    section
  },
  location,
}) => {
  const filterRules = category.overrideFilters
    ? category.filterRules
    : [...section.filterRules, ...category.filterRules]

  return (
    <CategoryPageLayout
      section={section}
      category={category}
      filterRules={filterRules}
      products={category.products}
      location={location}
    />
  )
}

export default CategoryPageTemplate
